<template>
  <div>
    <p>
      The LEGO Universe team had a blast in Swindon, UK at the Great Western LEGO Show! The event drew over 5,500 visitors in two days, and the LEGO Universe team was visited by over 1,100 children, who came and built models of what they’d like to see included in the game. Check out some of the highlights in the photos below. Keep on creating, LEGO Universe fans… We love it!
    </p>
    <!-- MISSING: pic136F8C36D6A0E60D342E10E7143833E7.jpg --><img class="rounded" src="">
    <!-- MISSING: picEBB6D2191A76C84AFD0564B16301C37F.jpg --><img class="rounded" src="">
    <!-- MISSING: pic01198A274D85FE93D604A35BA77A877D.jpg --><img class="rounded" src="">
    <!-- MISSING: pic1DB404F8BF81046566745FC897784310.jpg --><img class="rounded" src="">
    <!-- MISSING: pic711EDC341292F7A2FAF16FDA650A5A45.jpg --><img class="rounded" src="">
  </div>
</template>
